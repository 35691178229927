import z from 'zod'
import {
  nullSafeBoolean,
  nullSafeNumber,
  nullSafeOptionalStringArray,
} from '../shared/schema'
import { SessionMuting, RoomStateVideoMethod } from '../../types'

const sessionMutingSchema = z.nativeEnum(SessionMuting)
const videoMethodSchema = z.nativeEnum(RoomStateVideoMethod)

export const schema = z.object({
  adminRoleManagementEnabled: nullSafeBoolean(false),
  allowDraftAssignments: nullSafeBoolean(false),
  allowEmailUpdate: nullSafeBoolean(false),
  allowPublishedSlideDeckEdit: nullSafeBoolean(false),
  animationMethod: nullSafeBoolean(true),
  automatedRandomGrouping: nullSafeBoolean(true),
  generateGoogleSlides: nullSafeBoolean(false),
  generateSessionSummary: nullSafeBoolean(true),
  rubricTestDesiredStdDeviation: nullSafeNumber(1.5),
  rubricTestMeanEpsilon: nullSafeNumber(0.9),
  sendInstructorEmails: nullSafeBoolean(false),
  sessionMuting: z.preprocess(
    (val) => val ?? SessionMuting.none,
    sessionMutingSchema
  ),
  showNewScheduling: nullSafeBoolean(false),
  sessionResultsGroupBalanceCoefficient: nullSafeNumber(0.5),
  sessionResultsShowAllConversationTooltips: nullSafeBoolean(true),
  sessionResultsUseNew: nullSafeBoolean(false),
  showRubricArgumentEventMatchMinimum: nullSafeNumber(1), // float value
  studentLibraryUseNew: nullSafeBoolean(false),
  useStrictPublishRequirements: nullSafeBoolean(false),
  videoMethod: z.preprocess(
    (val) => val ?? RoomStateVideoMethod.broadcast,
    videoMethodSchema
  ),
  showOrganizationsUI: nullSafeBoolean(false),
  avTroubleshootingEnabled: nullSafeBoolean(true),
  meetingFeedbackAsStarsRatio: nullSafeNumber(0),
  showNotifications: nullSafeBoolean(false),
  transcriptionLanguages: nullSafeOptionalStringArray(),
  transcriptProblemWaitDurationMS: nullSafeNumber(0), // in ms
  verifyInvitation: nullSafeBoolean(false),
  forceGradeExpiredRooms: nullSafeBoolean(false),
  forceLoginAccountSelect: nullSafeBoolean(false),
  showAssignmentCSVResultsInMinutesAndSortingAsPercent: nullSafeBoolean(false),
  connectionQualityIndicators: nullSafeBoolean(false),
  demoVideoMethod: z.preprocess((val) => {
    // this is protection against bad data - if it's streaming,
    // use it, otherwise default to broadcast
    if (val === RoomStateVideoMethod.streaming) return val
    return RoomStateVideoMethod.broadcast
  }, videoMethodSchema),
})

export const empty = Object.freeze(schema.parse({}))

export type FirestoreSettingsFeatureFlags = z.infer<typeof schema>
