import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { getSlideRubrics } from '../firestore/SlideRubric'
import type { StaticModelCollection } from '../types'
import { SlideRubric } from '../models/SlideRubric'
import { type MeetingCubit } from './MeetingCubit'
import { RoomStateEngagement } from '../models/RoomStateEngagement'
import {
  type IObservableArray,
  action,
  computed,
  makeObservable,
  observable,
  type ObservableMap,
  when,
  runInAction,
} from 'mobx'
import { SlideQuestionType } from '../models/SlideQuestionType'
import { similarity } from '../util/arrays'
import {
  getQuizAnswerScore,
  isMeetingQuestionScorable,
} from '../sources-of-truth/scoring'
import { getRoomStateEngagementStreamForRoomState } from '../firestore/RoomStateEngagement'
import { RoomStateRubricResult } from '../models/RoomStateRubricResult'
import {
  getRoomStateRubricResultsForUser,
  getRoomStateRubricResultStreamForAssignment,
} from '../firestore/RoomStateRubricResult'
import { RoomStateRubricResultDetail } from '../models/RoomStateRubricResultDetail'
import {
  getRoomStateRubricResultDetailsForAssignment,
  getRoomStateRubricResultDetailsForRoom,
} from '../firestore/RoomStateRubricResultDetail'
import { submitRubricFeedback } from '../firestore/RoomStateRubricResultFeedback'
import { collection, orderBy, query } from 'firebase/firestore'
import { collectionSnapshots } from '../firestore-mobx/stream'
import { DateTime } from 'luxon'
import { sortRubrics, sortRubricResults } from '../sources-of-truth/rubrics'

export class MeetingResultsCubit extends Cubit {
  repository: FirebaseRepository
  slideDeckId: string
  roomStateId: string
  inDialog: boolean

  @observable userId: string

  rubrics: StaticModelCollection<SlideRubric>
  meetingCubit: MeetingCubit

  rubricResultDetails: StaticModelCollection<RoomStateRubricResultDetail>
  protected _didSeedData: boolean = false
  protected _roomStateEngagement: StaticModelCollection<RoomStateEngagement>
  protected _aiStatusResultsSorted: IObservableArray<
    'pending' | 'success' | 'failure'
  >

  protected _rubricResultsByUser: ObservableMap<
    string,
    StaticModelCollection<RoomStateRubricResult>
  >

  constructor(
    repository: FirebaseRepository,
    params: {
      roomStateId: string
      slideDeckId: string
      meetingCubit: MeetingCubit
      userId: string
      inDialog: boolean
      /**
       * if seed data is provided it will populate the respective
       * cubit models and skip fetching via streams
       */
      seedData?: {
        roomStateEngagement: RoomStateEngagement[]
        rubricResultDetails: RoomStateRubricResultDetail[]
        rubricResults: RoomStateRubricResult[]
        rubrics: SlideRubric[]
      }
    }
  ) {
    super()
    this.inDialog = params.inDialog
    this.repository = repository
    this.roomStateId = params.roomStateId
    this.slideDeckId = params.slideDeckId
    this.meetingCubit = params.meetingCubit
    this.rubrics = SlideRubric.emptyCollection(repository)
    this.userId = params.userId
    this._aiStatusResultsSorted = observable.array([])
    this._rubricResultsByUser = observable.map()
    this._roomStateEngagement = RoomStateEngagement.emptyCollection(repository)
    this.rubricResultDetails =
      RoomStateRubricResultDetail.emptyCollection(repository)

    if (params.seedData) {
      this._didSeedData = true
      this._roomStateEngagement.replaceModels(
        params.seedData.roomStateEngagement
      )
      this.rubricResultDetails.replaceModels(
        params.seedData.rubricResultDetails
      )
      this.rubrics.replaceModels(params.seedData.rubrics)

      // assume success on ai status
      this._aiStatusResultsSorted.replace(['success'])

      const rubricResultsPerUser = params.seedData.rubricResults.reduce(
        (acc, result) => {
          const { userId } = result.data
          if (!acc.has(userId)) {
            acc.set(userId, [])
          }
          acc.get(userId)?.push(result)
          return acc
        },
        new Map<string, RoomStateRubricResult[]>()
      )

      rubricResultsPerUser.forEach((results, userId) => {
        const collection = RoomStateRubricResult.emptyCollection(repository)
        const sortedResults = sortRubricResults(results, this.rubrics.models)
        collection.replaceModels(sortedResults)
        this._rubricResultsByUser.set(userId, collection)
      })
    }

    makeObservable(this)
  }

  initialize(): void {
    if (this._didSeedData) return

    this.addStream(
      getSlideRubrics(this.repository, { slideDeckId: this.slideDeckId }),
      (rubrics) => {
        const sortedRubrics = sortRubrics(rubrics)
        this.rubrics.replaceModels(sortedRubrics)
      }
    )

    this.initRubricsResultsForUserStream()

    this.addStream(
      getRoomStateEngagementStreamForRoomState(this.repository, {
        roomId: this.meetingCubit.roomId,
      }),
      (engagement) => {
        this._roomStateEngagement.replaceModels(engagement)
      }
    )

    this.addStream(this.getAIStatusStream(), (aiStatusResultsSorted) => {
      runInAction(() => {
        this._aiStatusResultsSorted.replace(aiStatusResultsSorted)
      })
    })

    // ensure roomState load before starting rubric results streams
    when(
      () => this.meetingCubit.roomState.isLoaded,
      () => {
        const { sectionId, assignmentId } = this.meetingCubit.roomState.data
        if (this.currentUserCanFetchRubricResultsByRoom)
          return this.addStream(
            getRoomStateRubricResultDetailsForRoom(this.repository, {
              roomId: this.roomStateId,
            }),
            (rubricResultDetails) => {
              this.rubricResultDetails.replaceModels(rubricResultDetails)
            }
          )

        // happy path means we should NEVER hit this, but we should know if we do via sentry
        if (!sectionId || !assignmentId)
          throw new Error(
            `missing assignment/section data on roomState: sectionId=${sectionId} assignmentId=${assignmentId}`
          )

        // don't init stream on old cubit
        if (this.isDisposed) return

        // from this point forward if we are viewing results without seed data for a room that
        // we are not a part of, let's assume we are faculty for this section (ta/instructor)

        // the perms for faculty are such that we must load all rubric results for the assignment
        // so we must over-fetch then filter on the client
        this.addStream(
          getRoomStateRubricResultDetailsForAssignment(this.repository, {
            assignmentId,
            sectionId,
          }),
          (rubricResultDetails) => {
            const filtered = rubricResultDetails.filter(
              (d) => d.data.roomId === this.roomStateId
            )
            this.rubricResultDetails.replaceModels(filtered)
          }
        )
        this.addStream(
          getRoomStateRubricResultStreamForAssignment(this.repository, {
            sectionId,
            assignmentId,
          }),
          (rubricResults) => {
            // builds answers for each user to replace in map
            const rubricResultsPerUser = rubricResults.reduce((acc, result) => {
              const { userId } = result.data
              if (!acc.has(userId)) {
                acc.set(userId, [])
              }
              acc.get(userId)?.push(result)
              return acc
            }, new Map<string, RoomStateRubricResult[]>())

            // replace in map
            rubricResultsPerUser.forEach((results, userId) => {
              const filtered = results.filter(
                (r) => r.data.roomId === this.roomStateId
              )
              const collection = RoomStateRubricResult.emptyCollection(
                this.repository
              )
              const sortedResults = sortRubricResults(
                filtered,
                this.rubrics.models
              )
              collection.replaceModels(sortedResults)
              this._rubricResultsByUser.set(userId, collection)
            })
          }
        )
      }
    )
  }

  @computed
  get rubricResultsForCurrentUser() {
    return (
      this._rubricResultsByUser.get(this.userId) ??
      RoomStateRubricResult.emptyCollection(this.repository)
    )
  }

  @computed
  get hideFeedbackForm() {
    return !this.meetingCubit.sectionId && !this.meetingCubit.assignmentId
  }

  logEvent(name: string, params?: Record<string, unknown>) {
    this.repository.logEvent(name, {
      room_id: this.roomStateId,
      user_id: this.userId,
      slide_deck_id: this.slideDeckId,
      section_id: this.meetingCubit.sectionId,
      assignment_id: this.meetingCubit.assignmentId,
      ...params,
    })
  }

  private initRubricsResultsForUserStream() {
    const uid = this.userId
    const name = `rubricResultsForUser-${uid}`

    // wait for roomState load to determine if we are room member
    when(
      () => this.meetingCubit.roomState.isLoaded,
      () => {
        // if seeded data we don't need to fetch here
        if (
          this._didSeedData ||
          !this.currentUserCanFetchRubricResultsByRoom ||
          this.isDisposed
        )
          return

        if (!this.hasStream(name)) {
          this.addStream(
            getRoomStateRubricResultsForUser(this.repository, {
              roomStateId: this.roomStateId,
              userId: this.userId,
            }),
            (rubricResults) => {
              const results = RoomStateRubricResult.emptyCollection(
                this.repository
              )
              const sortedResults = sortRubricResults(
                rubricResults,
                this.rubrics.models
              )
              results.replaceModels(sortedResults)
              this._rubricResultsByUser.set(uid, results)
            },
            { name }
          )
        }
      }
    )
  }

  @action
  submitFeedback(rubricId: string, feedback: string) {
    return submitRubricFeedback(this.repository, {
      rubricId,
      feedback,
      roomId: this.roomStateId,
      userId: this.userId,
      sectionId: this.meetingCubit.sectionId!,
      assignmentId: this.meetingCubit.assignmentId!,
    })
  }

  @action
  changeUserId(userId: string) {
    this.userId = userId
    this.initRubricsResultsForUserStream()
  }

  @computed
  get rubricsById() {
    return this.rubrics.models.reduce(
      (acc, rubric) => {
        acc[rubric.id] = rubric
        return acc
      },
      {} as Record<string, SlideRubric>
    )
  }

  @computed
  get rubricResultsByRubricId() {
    return sortRubricResults(
      this.rubricResultsForCurrentUser.models,
      this.rubrics.models
    ).reduce(
      (acc, rubricResult) => {
        acc[rubricResult.rubricId] = rubricResult
        return acc
      },
      {} as Record<string, RoomStateRubricResult>
    )
  }

  @computed
  get engagementStatus() {
    const hasEngagement = this._roomStateEngagement.models.length > 0

    // if we have all the engagement data return success regardless of status
    if (hasEngagement && !this.meetingCubit.usersLoading) return 'success'

    if (
      this._roomStateEngagement.isLoading ||
      this.aiStatus === 'pending' ||
      this.meetingCubit.usersLoading
    )
      return 'pending'

    if (!hasEngagement) return 'failure'
  }

  @computed
  get aiStatus() {
    // If we have a success, return success.
    if (this._aiStatusResultsSorted.includes('success')) {
      return 'success'
    }

    if (this._aiStatusResultsSorted.includes('failure')) {
      return 'failure'
    }

    return 'pending'
  }

  @computed
  get engagementByUserId() {
    const hiddenUserIds = new Set(this.meetingCubit.hiddenUserIds)
    return {
      data: this._roomStateEngagement.models.reduce(
        (acc, engagement) => {
          // skip hidden users
          if (hiddenUserIds.has(engagement.id)) return acc
          acc[engagement.data.userId] = engagement
          return acc
        },
        {} as Record<string, RoomStateEngagement>
      ),
      isLoading: this._roomStateEngagement.isLoading,
      hasDocuments: this._roomStateEngagement.hasDocuments,
    }
  }

  @computed
  get userEngagementData() {
    return this.userId in this.engagementByUserId.data
      ? this.engagementByUserId.data[this.userId]
      : RoomStateEngagement.empty(this.repository)
  }

  @computed
  get currentUserCanFetchRubricResultsByRoom() {
    return (
      this.meetingCubit.roomState.userIds.includes(this.repository.uid) ||
      this.repository.breakoutUser?.isCorre === true
    )
  }

  @computed
  get sessionResults() {
    /// loop over state.slideQuestions and get questions
    /// if it is a poll type question
    /// loop over state.roomStateAnswersByQuestion and get answers
    /// extract an array of bools from the from questions that are of
    /// type poll. 1 = true, 0 = false
    /// pass the list to the similarity function
    /// add the results from each question to a double and then divide
    /// by the number of questions and set that to pollAgreement
    /// calculate the quizPerformance as well
    /// (simple average of correct answers)
    let pollTotal = 0.0
    let pollCount = 0

    let groupScore = 0
    let highestPossibleGroupScore = 0

    for (const question of this.meetingCubit.questions) {
      const isPollQuestion = [
        SlideQuestionType.poll,
        SlideQuestionType.customPoll,
      ].includes(question.questionType)

      if (isPollQuestion) {
        const answers = this.meetingCubit.questionAnswers.get(question.id) ?? []
        const answerList = answers.map((answer) => answer.data.answer === 1)
        pollTotal += similarity(answerList)
        pollCount++
      }

      if (isMeetingQuestionScorable(question)) {
        const answers = this.meetingCubit.questionAnswers.get(question.id) ?? []
        for (const answer of answers) {
          groupScore += getQuizAnswerScore({
            answer,
            question: question.slideQuestion,
          })
          highestPossibleGroupScore++
        }
      }
    }

    const pollAgreement =
      Math.round((pollTotal / Math.max(pollCount, 1)) * 100) / 100
    const quizPerformance =
      Math.round((groupScore / Math.max(highestPossibleGroupScore, 1)) * 100) /
      100

    const engagementDataArr = this._roomStateEngagement.models
    const talkTimeMinutes =
      (this.userEngagementData.data.userTalkTime || 0) / 60
    const groupAverageTalkTimeMinutes =
      engagementDataArr.reduce(
        (acc, { data: { userTalkTime } }) => acc + (userTalkTime || 0),
        0
      ) /
      60 /
      engagementDataArr.length
    const cameraOnPercent = (this.userEngagementData.cameraOnRatio || 0) * 100

    const groupCameraOnRatios = engagementDataArr
      .map((d) => d.cameraOnRatio)
      .filter((r) => r !== null) as number[]

    const groupAverageCameraOnPercent =
      (groupCameraOnRatios.reduce((total, ratio) => total + ratio, 0) /
        groupCameraOnRatios.length) *
      100

    return {
      isLoaded:
        this._roomStateEngagement.isLoaded &&
        this.meetingCubit.slideDeckQuestions.isLoaded &&
        this.meetingCubit.roomStateAnswersForGroup.isLoaded,
      talkTimeMinutes,
      groupAverageTalkTimeMinutes,
      cameraOnPercent,
      groupAverageCameraOnPercent,
      pollAgreement,
      quizPerformance,
      userQuizPerformance: this._userQuizPerformance,
    }
  }

  @computed
  /**
   * Calculate the user's quiz performance a float between 0 and 1
   * if null there was no quiz answer data for user
   */
  private get _userQuizPerformance() {
    let score = 0
    let quizCount = 0

    for (const question of this.meetingCubit.questions) {
      if (isMeetingQuestionScorable(question)) {
        quizCount++

        const userAnswersMaybe = this.meetingCubit.roomStateAnswersPerUser.get(
          this.userId
        )
        const userAnswers = userAnswersMaybe ? userAnswersMaybe.models : []

        const userAnswer = userAnswers.find(
          (a) => a.data.slideQuestionId === question.id
        )

        if (userAnswer) {
          score =
            score +
            getQuizAnswerScore({
              answer: userAnswer,
              question: question.slideQuestion,
            })
        }
      }
    }

    if (!quizCount) return null
    return Math.round((score / Math.max(quizCount, 1)) * 100) / 100
  }

  @computed
  get conversationMapDataLoading() {
    return this.rubricResultDetails.isLoading
  }

  @computed
  private get timeBoundsForConversationMap(): {
    earliest: Date | undefined | null
    latest: Date | undefined | null
  } {
    // get earliest / latest result detail times
    const data = this.rubricResultDetails.models.reduce<{
      earliest: Date | null
      latest: Date | null
    }>(
      (acc, { data: { eventTime } }) => {
        const { earliest, latest } = acc
        if (!earliest || eventTime < earliest) {
          acc.earliest = eventTime
        }
        if (!latest || eventTime > latest) {
          acc.latest = eventTime
        }
        return acc
      },
      { earliest: null, latest: null }
    )

    // do not continue of no earliest/latest for results
    if (data.earliest === null || data.latest === null) return data

    /** determine if details are in a range of times (not all the same time)*/
    const detailsCoverTimeRange =
      DateTime.fromJSDate(data.latest).diff(DateTime.fromJSDate(data.earliest))
        .milliseconds > 0

    const { roomStartedAt, activeSlideChangedAt } =
      this.meetingCubit.roomState.data

    const activeSlideChangedAtDateTime =
      activeSlideChangedAt && DateTime.fromJSDate(activeSlideChangedAt)
    const roomStartedAtDateTime =
      roomStartedAt && DateTime.fromJSDate(roomStartedAt)

    const earliestDateTime = DateTime.fromJSDate(data.earliest)
    const latestDateTime = DateTime.fromJSDate(data.latest)

    if (activeSlideChangedAtDateTime && roomStartedAtDateTime) {
      // if no time span to render details (all same time or one result), use room started at and active slide changed at
      if (!detailsCoverTimeRange)
        return {
          earliest: roomStartedAt,
          latest: activeSlideChangedAt,
        }

      const meetingLengthMinutes = activeSlideChangedAtDateTime.diff(
        roomStartedAtDateTime,
        'minutes'
      ).minutes

      // if meeting is longer than 90 minutes, do not use earliest/latest details times as the bounds
      if (meetingLengthMinutes > 90) return data

      // if active slide changed at is <10 mins ahead of last event, use it as end time
      if (activeSlideChangedAtDateTime > latestDateTime) {
        const activeSlideChangedAtMinutesAhead = latestDateTime.diff(
          activeSlideChangedAtDateTime,
          'minutes'
        ).minutes

        if (activeSlideChangedAtMinutesAhead < 10)
          data.latest = activeSlideChangedAtDateTime.toJSDate()
      }

      // if room started at is <10 mins behind first event, use it as start time
      if (roomStartedAtDateTime < earliestDateTime) {
        const earliestDateTimeMinutesBehind = earliestDateTime.diff(
          roomStartedAtDateTime,
          'minutes'
        ).minutes
        if (earliestDateTimeMinutesBehind < 10)
          data.earliest = roomStartedAtDateTime.toJSDate()
      }
    }

    return data
  }

  @computed
  /**
   * Computes data for the conversation map visualization by:
   * 1. Getting the time bounds for the conversation
   * 2. Sorting all rubric results by time
   * 3. Dividing the conversation into 5 equal time buckets
   * 4. Distributing the results into the appropriate time buckets
   *
   * @returns Object containing:
   * - startTimeTS: Start timestamp of the conversation
   * - endTimeTS: End timestamp of the conversation
   * - buckets: Array of 5 buckets containing rubric results
   * - minutesPerBucket: Duration of each bucket in minutes
   */
  get conversationMapData() {
    // Get the earliest and latest timestamps for the conversation
    const { earliest, latest } = this.timeBoundsForConversationMap

    if (!earliest || !latest) return undefined

    const timeData = {
      firstEventTime: earliest,
      lastEventTime: latest,
      startTimeTS: earliest.getTime(),
      endTimeTS: latest.getTime(),
      meetingDurationMs: latest.getTime() - earliest.getTime(),
    }

    // Sort all rubric results by timestamp
    const sortedResults = this.rubricResultDetails.models
      .concat()
      .sort((a, b) => a.data.eventTime.getTime() - b.data.eventTime.getTime())

    if (sortedResults.length === 0) return undefined

    // Divide conversation into 5 equal time buckets
    const bucketCount = 5
    const bucketSizeMilliseconds = timeData.meetingDurationMs / bucketCount

    // Initialize array of empty buckets
    const buckets: RoomStateRubricResultDetail[][] = Array.from({
      length: bucketCount,
    })

    for (let i = 0; i < bucketCount; i++) {
      buckets[i] = []
    }

    // Distribute results into appropriate time buckets
    for (const rubricResultDetail of sortedResults) {
      const eventTime = rubricResultDetail.data.eventTime.getTime()
      const pointInTime = eventTime - timeData.startTimeTS
      const bucketIndex = Math.floor(
        (pointInTime > 0 ? pointInTime - 1 : pointInTime) /
          bucketSizeMilliseconds
      )

      if (bucketIndex >= 0 && bucketIndex < bucketCount) {
        buckets[bucketIndex].push(rubricResultDetail)
      }
    }

    const startTimeTS = timeData.startTimeTS
    const endTimeTS = timeData.endTimeTS

    return {
      startTimeTS,
      endTimeTS,
      buckets,
      minutesPerBucket: bucketSizeMilliseconds / 1000 / 60,
    }
  }

  generateConversationMapScaleData(
    buckets: RoomStateRubricResultDetail[][],
    realWidth: number
  ) {
    /**
     * low end estimate for packing efficiency of circles to calculate height pessimistically.
     * Perfect packing efficiency is about ~90% for same radius circles packed in a grid.
     * Here we are starting at 65% and will scale **down** if the height is proves to be
     * excessive (circle svg's clipping out of bounds). If need be adjusted scale down in 5% increments
     */
    const pessimisticPackingEfficiencyFactor = 0.65

    let startSize = 30
    let scoreMultiplier = 10
    let parentHeight = 200
    const bucketWidth = 500
    const circleScaleFactor = realWidth / bucketWidth

    // parentHeight - 33 = bucket height
    const bucketLabelHeight = 33
    const getBucketHeight = () => parentHeight - bucketLabelHeight
    const getNewParentHeight = (bucketHeight: number) =>
      bucketHeight + bucketLabelHeight

    let pessimisticBucketHeight = Infinity

    while (pessimisticBucketHeight > getBucketHeight()) {
      const bucketHeight = getBucketHeight()
      const maxCumulativeCircleArea = buckets.reduce((maxSoFar, results) => {
        const sumCircleArea = results.reduce<number>((acc, resultDetail) => {
          const {
            data: { score },
          } = resultDetail
          const radius =
            (startSize + score * scoreMultiplier) * circleScaleFactor
          const area = Math.PI * radius * radius
          return acc + area
        }, 0)
        return Math.max(maxSoFar, sumCircleArea)
      }, 0)

      // use the pessimistic efficiency factor to determine the approximate bucket height
      const heightNoInefficiency = maxCumulativeCircleArea / realWidth
      pessimisticBucketHeight =
        heightNoInefficiency / pessimisticPackingEfficiencyFactor

      // scale min size from 30 to minimum of 10, in 5 pixel increments
      // then scale down score multiplier from 5 to 10
      // once circles reach min size increase bucket height to pessimistic height
      // too accommodate display of all circles
      if (pessimisticBucketHeight > bucketHeight) {
        if (startSize > 15) {
          startSize -= 5
          continue
        }
        if (scoreMultiplier > 5) {
          scoreMultiplier -= 1
          continue
        }
        // if we've exhausted all scaling options, set bucket height to pessimistic and round up to the nearest 10
        parentHeight = getNewParentHeight(
          Math.ceil(pessimisticBucketHeight / 10) * 10
        )
      }
    }

    const data = {
      startSize,
      scoreMultiplier,
      parentHeight,
    }

    return data
  }

  private getAIStatusStream() {
    const colRef = collection(
      this.repository.firestore,
      `room_state/${this.roomStateId}/ai`
    )
    // show latest startedAt first
    const q = query(colRef, orderBy('startedAt', 'desc'))
    return collectionSnapshots(q).map((snapshots) => {
      return snapshots.docs.map((doc) => {
        // Nil state is pending.
        let result: 'pending' | 'success' | 'failure' = 'pending'
        const data = doc.data()

        if ('success' in data && typeof data.success === 'boolean') {
          result = data.success ? 'success' : 'failure'
        }

        return result
      })
    })
  }
}
